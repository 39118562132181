/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from '@styles/common-scss/navbar/_searchForm.module.scss';
import cardListstyles from '@styles/default-page-scss/list-cards/_list.module.scss';
import algoliasearch from 'algoliasearch/lite';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { InstantSearch, useHits, useInstantSearch, usePagination, useSearchBox } from 'react-instantsearch';

import { formatAlgoliaResults } from '../../../../../lib/repository/algoliaFormatConverter';
//import { CardDataArrayType } from '../../../../../lib/types/cardProps';
import Card from '../../card';
type Props = {
    setExpand: (expand: boolean) => void;
    searchRef: { current: HTMLDivElement };
};
export default function SearchForm({ setExpand, searchRef }: Props): JSX.Element {
    const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID, process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD);
    const router = useRouter();

    //dummy holiday object to put in for the Card Component
    const holiday = {
        name: '',
        color: '',
        path: '',
        promoLabel: ''
    };
    const searchPanel = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.which === 13) {
                event.target.blur();
            }
        };

        searchRef.current.addEventListener('keyup', handleKeyUp);
    }, []);

    function CustomHits(props) {
        const { hits, sendEvent } = useHits(props);

        const modifiedHits = [];
        //turning the hits into the structure the cards want
        hits.map((item) => modifiedHits.push(formatAlgoliaResults(item)));
        const { status } = useInstantSearch();
        if (hits && searchPanel && searchPanel.current) {
            searchPanel.current.scrollTop = 0;
        }
        return (
            <section className={`${cardListstyles.listSection} ${styles.searchList}`}>
                <div className={`${cardListstyles.allCards} ${styles.searchAllCards}`}>
                    <div className={`${cardListstyles.cardList} ${styles.searchCardList}`}>
                        {status === 'idle' && hits && hits.length > 0 ? (
                            modifiedHits.map((card, index) => (
                                <div className={cardListstyles.cardDiv} key={`search-result-${card.siteItemProps.id}-${index}`}>
                                    <Card
                                        site={card}
                                        holiday={holiday}
                                        pageTag={'search-results'}
                                        index={index}
                                        eventSender={sendEvent}
                                        eventData={hits[index]}
                                    />
                                </div>
                            ))
                        ) : status === 'loading' ? (
                            ''
                        ) : (
                            <div className={styles.noResult}>
                                <p>
                                    We could not find a result for your search. Please,
                                    <Link href={'/customer-support/contact-us'}>
                                        <strong>
                                            <a> Email Us </a>
                                        </strong>
                                    </Link>
                                    if you are looking for a specific type of site, or check out our
                                    {router.asPath === '/' ? (
                                        <button
                                            onClick={() => {
                                                setExpand(false);
                                            }}>
                                            Latest Reviews
                                        </button>
                                    ) : (
                                        <Link href={'/'}>
                                            <strong>
                                                <a> Latest Reviews </a>
                                            </strong>
                                        </Link>
                                    )}
                                    .
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }

    function RRSearchBox(props) {
        //query is what the user is searching
        //refine is used to trigger the search for the query
        const { query, refine } = useSearchBox(props);
        if (sessionStorage.getItem('searchPanel') && query) {
            const searchPanel = JSON.parse(sessionStorage.getItem('searchPanel'));
            sessionStorage.setItem(
                'searchPanel',
                JSON.stringify({ page: String(searchPanel.page), word: query, route: router.asPath })
            );
        }
        return (
            <>
                <div className={styles.searchInputContainer}>
                    <input
                        type="text"
                        id="rr-header-search-form-mobile-input"
                        name="headerSearchInput"
                        className={styles.inputField}
                        placeholder="Search Rabbits"
                        autoComplete="off"
                        aria-label="Search"
                        onChange={(e) => {
                            refine(e.target.value);
                        }}
                        ref={props.searchRef}
                        value={query}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={true}
                    />
                    <button className={styles.submitButton}>SEARCH</button>
                </div>
            </>
        );
    }

    function CustomPagination(props) {
        const { pages, currentRefinement, nbPages, refine } = usePagination(props);

        if (sessionStorage.getItem('searchPanel') && currentRefinement) {
            const searchPanel = JSON.parse(sessionStorage.getItem('searchPanel'));
            sessionStorage.setItem(
                'searchPanel',
                JSON.stringify({ page: String(currentRefinement), word: searchPanel.word, route: router.asPath })
            );
        }
        return (
            <>
                {nbPages ? (
                    <div className={styles.paginationContainer}>
                        <ul className={styles.paginationList}>
                            {currentRefinement === 0 ? (
                                ''
                            ) : (
                                <>
                                    <li className={styles.pageItem}>
                                        <a
                                            href="#"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                refine(0);
                                            }}>
                                            {`<<`}
                                        </a>
                                    </li>
                                    <li className={styles.pageItem}>
                                        <a
                                            href="#"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                refine(currentRefinement - 1);
                                            }}>
                                            {`<`}
                                        </a>
                                    </li>
                                </>
                            )}
                            {pages.map((page) => (
                                <li
                                    key={page}
                                    className={`${styles.pageItem} ${currentRefinement === page ? styles.selected : ''}`}>
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            refine(page);
                                        }}>
                                        {page + 1}
                                    </a>
                                </li>
                            ))}
                            {currentRefinement + 1 === nbPages ? (
                                ''
                            ) : (
                                <>
                                    <li className={styles.pageItem}>
                                        <a
                                            href="#"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                refine(currentRefinement + 1);
                                            }}>
                                            {`>`}
                                        </a>
                                    </li>
                                    <li className={styles.pageItem}>
                                        <a
                                            href="#"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                refine(nbPages - 1);
                                            }}>
                                            {`>>`}
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    }

    return (
        <div ref={searchRef}>
            <InstantSearch
                searchClient={searchClient}
                indexName="RRX"
                insights={true}
                initialUiState={{
                    RRX: {
                        query: sessionStorage.getItem('searchPanel')
                            ? JSON.parse(sessionStorage.getItem('searchPanel')).word
                            : '',
                        page: sessionStorage.getItem('searchPanel')
                            ? Number(JSON.parse(sessionStorage.getItem('searchPanel')).page) + 1
                            : 0
                    }
                }}>
                <div className={styles.searchContainer} ref={searchPanel}>
                    <RRSearchBox />
                    <CustomHits />

                    <CustomPagination />
                </div>
            </InstantSearch>
        </div>
    );
}
