export const formatAlgoliaResults = (item) => {
    return {
        categoryItemProps: {
            id: Number(item.primaryCategoryId),
            name: item.primaryCategoryName,
            slug: String(item.primaryCategorySlug)
        },
        priceItemProps: {
            isFlash: Boolean(item.isFlash),
            isLimitedTrial: false,
            isSpecial: false,
            isTrial: false,
            length: Number(item.primaryLength),
            lifetimeDiscount: false,
            price: item.price,
            pricePerUnit: '0.00',
            primaryTrialIsLimited: true,
            primaryTrialPrice: '0.00',
            rebillPrice: '0.00',
            regularPrice: String(item.primaryRegularPrice),
            savings: Number(item.primarySavings),
            unit: String(item.unitType)
        },
        siteItemProps: {
            catListDescription: String(item.catListDescription),
            hasFlash: Boolean(item.isFlash),
            id: Number(item.objectID),
            isActive: Boolean(item.isActive),
            isTopSite: Boolean(item.isTopSite),
            name: String(item.name),
            promoLabel: '',
            slug: String(item.reviewSlug),
            thumb: String(item.thumb450),
            thumbID: item.thumbId,
            totalScore: Number(item.score)
        }
    };
};
