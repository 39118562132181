/**
 * The magnifying glass in the mobile navbar
 * @returns SVG
 */
import styles from '@styles/splash-page-scss/_navbarSplash.module.scss';

export default function svgNavZoomIcon(): JSX.Element {
    return (
        <>
            <svg width="35" height="35" viewBox="0 0 36 37" className={styles.searchIcon} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.5 29C23.1274 29 28.5 23.6274 28.5 17C28.5 10.3726 23.1274 5 16.5 5C9.87258 5 4.5 10.3726 4.5 17C4.5 23.6274 9.87258 29 16.5 29Z"
                    strokeWidth="3"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M31.4999 32L24.9749 25.475" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
