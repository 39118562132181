/**
 * The large bunny logo displayed on the splash page
 * @returns SVG
 */
import styles from '@styles/common-scss/navbar/_navbar.module.scss';

export default function svgNavLogoWhite(): JSX.Element {
    return (
        <>
            <svg className={styles.navLogo} viewBox="0 0 119 33" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.5 1H32.5V33C21.8333 33 11.1667 33 0.5 33C0.5 22.4349 0.5 11.7683 0.5 1.10159V1Z"
                    fill="white"
                    stroke="none"
                />
                <path
                    d="M9.54128 20.8096C10.6587 21.5207 11.7762 22.2318 12.8937 22.8413C11.6746 24.6699 8.42382 23.0445 9.54128 20.8096Z"
                    fill="#009C06"
                    stroke="none"
                />
                <path
                    d="M23.5604 21.3175C24.5762 23.654 21.1223 24.9746 20.1064 23.146C21.3254 22.5365 22.3413 21.927 23.5604 21.3175Z"
                    fill="#009C06"
                    stroke="none"
                />
                <path
                    d="M0.5 1H32.5V33C21.8333 33 11.1667 33 0.5 33C0.5 22.4349 0.5 11.7683 0.5 1.10159V1ZM12.5889 8.82222C12.081 7.09524 11.573 5.16508 10.4556 4.14921C9.94762 3.64127 9.03333 3.4381 8.32222 3.84444C6.39206 4.96191 6.9 8.2127 7.50952 10.4476C8.22064 12.7841 9.13492 14.8159 10.0492 16.5429C7.61111 18.8794 6.18889 22.2317 5.98571 26.9048C6.69683 26.1937 7.40794 25.2794 8.83016 25.0762C11.2683 24.7714 12.4873 26.8032 13.9095 27.7175C14.1127 27.1079 14.3159 26.6 14.6206 25.9905C15.1286 26.0921 15.6365 26.0921 16.0429 26.1937C15.8397 26.8032 15.6365 27.4127 15.4333 28.0222C15.3317 28.2254 15.1286 28.4286 15.4333 28.5302C15.6365 28.5302 15.6365 28.327 15.7381 28.1238C16.0429 27.5143 16.246 26.9048 16.4492 26.3968C16.9571 26.4984 17.4651 26.4984 17.8714 26.6C17.5667 27.5143 17.2619 28.327 16.9571 29.1397C21.9349 30.3587 26.4048 28.0222 26.3032 23.2476C26.3032 20.2 24.5762 18.3714 22.9508 16.6444C24.5762 14.3079 26.2016 11.9714 27.319 9.22857C27.7254 8.31429 28.1317 7.29841 28.1317 6.07937C28.1317 4.86032 27.319 3.74286 25.7952 3.84444C24.373 3.94603 23.5603 5.16508 22.8492 6.18095C21.2238 8.61905 20.2079 11.9714 19.2937 14.8159C17.7698 14.3079 15.4333 14.1048 13.6048 14.4095C13.5032 12.6825 13.0968 10.6508 12.5889 8.82222Z"
                    fill="#009C06"
                    stroke="none"
                />
                <path
                    stroke="none"
                    d="M41.0561 32.6935L38.3927 27.6873H37.1634V32.6935H34.5V19.6161H39.622C44.3341 19.6161 44.7439 26.1548 40.9537 27.2787L44.0268 32.6935H41.0561ZM37.1634 25.2353H39.5195C41.261 25.2353 41.261 22.2725 39.5195 22.2725H37.1634V25.2353Z"
                />
                <path
                    stroke="none"
                    d="M44.0269 32.6935V19.6161H50.583V22.2725H46.6903V24.9288H50.1732V27.5852H46.6903V30.2415H50.8903V32.6935H44.0269Z"
                />
                <path
                    stroke="none"
                    d="M55.8073 33L50.3781 19.6161H53.4512L55.1927 24.0093L56.1146 26.6656L57.0366 24.0093L58.7781 19.5139H61.8512L56.422 33H55.8073Z"
                />
                <path stroke="none" d="M61.7488 32.6935V19.6161H64.4122V32.6935H61.7488Z" />
                <path
                    stroke="none"
                    d="M65.4366 32.6935V19.6161H71.9927V22.2725H68.1V24.9288H71.5829V27.5852H68.1V30.2415H72.3V32.6935H65.4366Z"
                />
                <path
                    stroke="none"
                    d="M77.1146 33L71.7878 19.6161H74.7585L77.4219 26.257L79.9829 19.7183L82.5439 26.257L85.2073 19.6161H88.178L82.8512 32.8979H82.339L79.9829 27.1765L77.6268 33H77.1146Z"
                />
                <path
                    stroke="none"
                    d="M87.5634 29.1177C89.1 30.1393 90.739 30.2415 91.3537 30.1393C92.1732 30.0372 92.7878 29.5263 92.6854 28.8112C92.5829 28.096 92.0707 27.6873 91.3537 27.483C89.3049 26.7678 87.461 25.644 87.461 23.4985C87.461 21.353 88.9976 19.6161 91.1488 19.5139C91.8659 19.5139 92.6854 19.5139 94.4268 20.0248L94.1195 22.579C92.6854 21.966 91.8659 21.966 91.4561 22.0681C90.5342 22.1703 90.1244 22.7833 90.1244 23.4985C90.2268 24.2136 90.8415 24.5201 91.5585 24.8266C93.6073 25.5418 95.3488 26.7678 95.3488 28.8112C95.3488 30.9567 93.8122 32.6935 91.3537 32.7957C90.6366 32.8978 88.9976 32.7957 87.2561 31.8762L87.5634 29.1177Z"
                />
                <path
                    stroke="none"
                    d="M43.4122 18.3901L39.8268 11.6471H38.0854V18.3901H34.5V0.613007H41.4659C47.8171 0.613007 48.4317 9.50155 43.2073 11.0341L47.3049 18.2879H43.4122V18.3901ZM38.0854 8.17338H41.261C43.7195 8.17338 43.7195 4.18886 41.261 4.18886H38.0854V8.17338Z"
                />
                <path
                    stroke="none"
                    d="M54.0659 0H54.4756L61.9537 18.3901H57.9586L57.2415 16.4489H51.3L50.5829 18.3901H46.5878L54.0659 0ZM55.9098 13.0774L54.2708 8.47988L52.6317 13.0774H55.9098Z"
                />
                <path
                    stroke="none"
                    d="M62.7732 0.613007H69.8415C73.0171 0.613007 74.9634 2.86069 74.861 6.02787C74.861 7.35604 74.3488 8.78638 73.222 9.50155C74.3488 10.3189 75.0659 11.6471 75.0659 13.0774C75.0659 16.0403 72.7098 18.3901 69.8415 18.3901H62.7732V0.613007ZM66.3586 7.76471H68.5098H69.5342C70.5586 7.76471 71.1732 6.94737 71.1732 6.02787C71.1732 5.0062 70.5586 4.29103 69.5342 4.29103H66.3586V7.76471ZM66.3586 14.8142H68.5098H69.5342C70.5586 14.8142 71.2756 13.9969 71.2756 13.0774C71.2756 12.1579 70.5586 11.2384 69.5342 11.2384H66.3586V14.8142Z"
                />
                <path
                    stroke="none"
                    d="M76.6024 0.613007H83.6707C86.8463 0.613007 88.7927 2.86069 88.6902 6.02787C88.6902 7.35604 88.178 8.78638 87.0512 9.50155C88.178 10.3189 88.8951 11.6471 88.8951 13.0774C88.8951 16.0403 86.539 18.3901 83.6707 18.3901H76.6024V0.613007ZM80.1878 7.76471H82.339H83.3634C84.3878 7.76471 85.0024 6.94737 85.0024 6.02787C85.0024 5.0062 84.3878 4.29103 83.3634 4.29103H80.1878V7.76471ZM80.1878 14.8142H82.339H83.3634C84.3878 14.8142 85.1049 13.9969 85.1049 13.0774C85.1049 12.1579 84.2853 11.2384 83.261 11.2384H80.0853V14.8142H80.1878Z"
                />
                <path stroke="none" d="M90.4317 18.3901V0.613007H94.0171V18.3901H90.4317Z" />
                <path stroke="none" d="M99.2415 18.3901V4.18886H94.8366V0.715179H107.232V4.18886H102.827V18.3901H99.2415Z" />
                <path
                    stroke="none"
                    d="M107.949 13.4861C109.998 14.9164 112.149 15.0186 112.968 14.9164C113.993 14.8142 114.915 14.0991 114.812 13.1796C114.71 12.1579 113.993 11.7492 112.968 11.3406C110.202 10.3189 107.744 8.88854 107.744 5.92569C107.744 2.96285 109.793 0.715168 112.763 0.510834C113.685 0.510834 114.915 0.510834 117.168 1.226L116.759 4.59752C114.812 3.78018 113.685 3.78018 113.173 3.88235C111.944 3.98452 111.329 4.90402 111.432 5.82353C111.534 6.8452 112.354 7.25387 113.378 7.56037C116.144 8.58204 118.5 10.1145 118.5 12.9752C118.5 15.9381 116.451 18.1858 113.173 18.3901C112.149 18.4923 109.998 18.3901 107.539 17.0619L107.949 13.4861Z"
                />
                <path
                    stroke="none"
                    d="M101.522 32.2848C101.522 32.7956 100.704 32.7956 100.704 32.2848C100.704 31.774 101.522 31.774 101.522 32.2848Z"
                />
                <path
                    stroke="none"
                    d="M98.661 29.7306C98.661 31.161 100.807 31.161 100.807 29.7306C100.807 29.4241 100.704 29.2198 100.602 29.0155L101.317 28.9133C101.42 29.1176 101.42 29.4241 101.42 29.7306C101.42 32.1827 97.8437 32.1827 97.8437 29.7306C97.8437 29.5263 97.8437 29.2198 97.9459 29.0155L98.661 29.1176C98.7632 29.2198 98.661 29.4241 98.661 29.7306Z"
                />
                <path
                    stroke="none"
                    d="M97.9459 26.7678C97.9459 24.3158 101.624 24.3158 101.624 26.7678C101.522 29.2198 97.9459 29.2198 97.9459 26.7678ZM98.661 26.7678C98.661 28.1981 100.807 28.1981 100.807 26.7678C100.807 25.3375 98.661 25.4396 98.661 26.7678Z"
                />
                <path
                    stroke="none"
                    d="M97.9459 21.4551V21.2508H101.522V21.9659H99.5805L100.5 22.8854L99.5805 23.8049H101.522V24.5201H97.9459V24.3158L99.4784 22.8854L97.9459 21.4551Z"
                />
            </svg>
        </>
    );
}
