import styles from '@styles/common-scss/navbar/_navbar.module.scss';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { holidayProps } from '../../../../lib/types/holidayProps';
import SvgX from '../../../svgs/svgBlackX';
import SvgNavLogo from '../../../svgs/svgNavLogoBlack';
import SvgNavZoom from '../../../svgs/svgNavZoomIcon';
import { useOnClickOutside } from '../handleOutsideClick';
import Burger from './navbar-burger/burger/burger';
import SearchForm from './searchForm/searchForm';
const Menu = dynamic(() => import('./navbar-burger/menu/menu'), {
    ssr: false
});
type Props = {
    holiday: holidayProps;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Navbar({ holiday, open, setOpen }: Props): JSX.Element {
    const router = useRouter();

    const [searchToggle, setSearchToggle] = useState<boolean>(false);

    const searchRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(searchRef, () => toggleSearch(false));

    //This local storage was used when we had login/sign up functionalities
    //We kept this useEffect so that it gets removed from all users who have
    //once logged in. Login was removed on PAYS-2022, May 2024.
    useEffect(() => {
        localStorage.removeItem('apiToken');
    });

    const getHolidayLogo = () => {
        const fileURIToImport = findHolidayFileLocation(holiday.name);
        const DynamicImport = dynamic(() => import(`../../../svgs/holidayLogoSvgs/${fileURIToImport}`));
        return <DynamicImport />;
    };
    const findHolidayFileLocation = (holidayName) => {
        switch (holidayName) {
            case 'Christmas Deals':
                return 'Christmas';
            case 'CYBER MONDAY DEALS':
                return 'CyberMonday';
            case 'Halloween Deals':
                return 'Halloween';
            case 'Black Friday':
                return 'BlackFriday';
            case 'Valentines Day':
                return 'Valentine';
            case 'THANKSGIVING DEALS':
                return 'Thanksgiving';
            case 'ST PATRICKS DAY':
                return 'StPatrick';
            case 'New Year Deals':
                return 'NewYear';
            case 'July 4th porn Deals':
                return 'July4th';
            case 'EASTER DEALS':
                return 'Easter';
            case 'Labor Day':
                return 'LaborDay';
            default:
                return '';
        }
    };

    useEffect(() => {
        //open the search menu if sessionStorage is there, meaning the user navigated to a review page
        //while they were in the search panel, and now they want to go back to where they were
        if (sessionStorage.getItem('searchPanel')) {
            const searchPanel = JSON.parse(sessionStorage.getItem('searchPanel'));
            if (searchPanel.route === router.asPath) {
                setSearchToggle(true);
            }
        }
    }, [router]);

    // Close the menu, if the search bar is open
    useEffect(() => {
        searchToggle ? setOpen(false) : '';
    }, [searchToggle]);

    // Activate or disable the main scroll
    useEffect(() => {
        const htmlElement = document.querySelector('html');
        const bodyElement = document.querySelector('body');

        if (open || searchToggle) {
            htmlElement.classList.add('noScroll');
            bodyElement.classList.add('noScroll');
        } else {
            htmlElement.classList.remove('noScroll');
            htmlElement.removeAttribute('class');

            bodyElement.classList.remove('noScroll');
            bodyElement.removeAttribute('class');
        }
    }, [open, searchToggle]);

    const toggleSearch = (toggleOption: boolean) => {
        //if the search menu is closed and searchPanel exist in sessionStorage, remove the searchPanel from sessionStorage
        //if the search menu is opening, add it back to sessionStorage
        //This is to keep a memory of where the user was in the search panel if they want to go back to it.
        if (searchToggle && sessionStorage.getItem('searchPanel')) {
            sessionStorage.removeItem('searchPanel');
        } else if (toggleOption) {
            sessionStorage.setItem('searchPanel', JSON.stringify({ page: '0', word: '', route: router.asPath }));
        }
        setSearchToggle(toggleOption);
    };

    return (
        <>
            <nav className={styles.nav} id="#top">
                <div className={styles.content}>
                    <div className={`${styles.hamburgerMenu} ${holiday && holiday.name ? styles.holidayHamburger : ''}`}>
                        <Burger open={open} setOpen={setOpen} />
                    </div>

                    <Link href={'/'}>
                        <a
                            title="Honest porn reviews"
                            className={`${styles.logoMobile} ${holiday && holiday.name ? styles.holidayLogo : ''}`}
                            data-event-category="User Action"
                            data-event-action="Header"
                            data-event-label="Logo">
                            {holiday && holiday.name ? getHolidayLogo() : <SvgNavLogo />}
                        </a>
                    </Link>
                    <div className={styles.iconWrapper}>
                        <ul className={styles.icons}>
                            <li>
                                <button
                                    className={styles.search}
                                    onClick={() => {
                                        toggleSearch(!searchToggle);
                                    }}>
                                    {searchToggle ? <SvgX /> : <SvgNavZoom />}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                {searchToggle ? <SearchForm setExpand={setSearchToggle} searchRef={searchRef} /> : ''}
            </nav>
        </>
    );
}
