import CheckMark from '@components/svgs/svgCheckMark';
import styles from '@styles/common-scss/card/_card.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import aa from 'search-insights';

import { MyContext } from '../../../context/provider';
import { calculateSavings } from '../../../lib/repository/savingsCalculator';
import { CardDataType } from '../../../lib/types/cardProps';
import { holidayProps } from '../../../lib/types/holidayProps';
import { placeHolderCard } from '../../../lib/web/config';
import VisitSiteCTA from './visitSiteCTA';
interface Type {
    site: CardDataType;
    holiday: holidayProps;
    index: number;
    pageTag?: string;
    eventSender?: (arg0: string, arg1: any, arg3: string) => void;
    eventData?: any;
    customHTML?: { description?: boolean; catLink?: boolean };
}

export default function Card({ site, holiday, index, pageTag, eventSender, eventData, customHTML }: Type): JSX.Element {
    const [loading, setLoading] = useState(true);
    const [isDisplay, setIsDisplay] = useState(true);
    const thumbInfo =
        site.siteItemProps.optimizedThumbInfo && site.siteItemProps.optimizedThumbInfo.thumb450
            ? { thumbID: site.siteItemProps.optimizedThumbInfo.id, thumb: site.siteItemProps.optimizedThumbInfo.thumb450 }
            : { thumbID: site.siteItemProps.thumbID, thumb: site.siteItemProps.thumb };
    const [imgSrc, setImgSrc] = useState(`https://assets.rabbitsreviews.com/images/${thumbInfo.thumb.toString()}`);
    const router = useRouter();
    const observedThumb = useRef(null);
    const relCategory = 'nofollow noreferrer';

    //Deal Cards will only show on the /porn/deals page, not even for the search bar
    const { dealIDs } = router && router.pathname === '/porn/deals' && !eventSender ? MyContext() : { dealIDs: [] };

    useEffect(() => {
        setLoading(false);
        /**
         * This function checks if the router query contains a 'cid' parameter.
         * If it does, it checks if the router's asPath includes the 'cid' value.
         * @param {NextRouter} router - The router object from Next.js
         * @returns {boolean} - Returns true if the 'cid' is found in the asPath, otherwise false.
         */

        if (router.isReady) {
            const { cid } = router.query;
            if (cid && router.asPath.includes(cid.toString())) {
                setIsDisplay(false);
            }
        }
    }, [router.isReady]);

    // This function finds out the correct event that needs to be sent to the Algolia
    // If the eventSender and eventData is available as props, that means Card is being rendered
    // in the Instant Search panel. In that case, we use those props to send the event. We also put the queryID
    // into a sessionStorage, so that we can send a clickedObjectIDsAfterSearch or convertedObjectIDsAfterSearch
    // event after you navigate to a review page.
    // The other way is sending the clickedObjectIDs or convertedObjectIDs events without the queryIDs
    // @param eventType can be 'conversion' or 'click'
    // @param eventName name to describe where the event is being clicked on ex. 'Card Full Review','Card Thumbnail'
    function createAlgoliaEvent(eventType: string, eventName: string) {
        if (eventSender && eventData) {
            const sessionStorageData = {
                queryID: eventData.__queryID,
                reviewSlug: site.siteItemProps.id
            };
            sessionStorage.setItem('algoliaQuery', JSON.stringify(sessionStorageData));
            eventSender(eventType, eventData, eventName);
        } else {
            aa('init', {
                appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
                apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD,
                useCookie: true, // since v2, this is false by default
                cookieDuration: 60 * 60 * 1000 // one hour, in milliseconds (default: 15552000000)
            });
            const algoliaType = eventType === 'click' ? 'clickedObjectIDs' : 'convertedObjectIDs';
            let sortValue = '';
            // If the card is on a Tag or Category page, we need to include the active tab in the event
            // before sending it to Algolia.
            if (router.pathname.indexOf('/porn/categories/') > -1 || router.pathname.indexOf('/porn/tag/') > -1) {
                sortValue =
                    router.query && router.query.sort
                        ? ` ${String(router.query.sort).charAt(0).toUpperCase() + String(router.query.sort).slice(1)}`
                        : ' Relevance';
            }
            // 64 characters is the max we can send to Algolia, that's why there's a split used for the eventName
            aa(algoliaType, {
                index: 'RRX',
                eventName: String(`${eventName}${sortValue}${router && router.asPath ? ` ${router.asPath}` : ''}`).slice(0, 64),
                objectIDs: [`${site.siteItemProps.id}`]
            });
        }
    }

    const thumb = undefined;
    const siteLink = undefined;

    const ThumbLoger = (InView, ThumbID, thumb, siteLink) => {
        if (InView === true && loading === false) {
            if (typeof thumb === 'undefined') thumb = 'il';
            if (typeof siteLink === 'undefined') siteLink = 'p';
            if (typeof ThumbID === 'undefined') ThumbID = 0;
            try {
                const lpath = process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/pthumb/' + ThumbID;
                fetch(lpath, {
                    method: 'POST'
                }).catch(function (e) {
                    // do nothing
                });
            } catch (err) {
                // do nothing
            }
        }
    };
    // Check if the value is null or empty, return ''
    // If it's not null or empty, then return value
    const linkValidator = (value: string) => {
        return value === undefined || value === null || value.trim() === '' ? '' : value;
    };

    /* Holiday substitutions */
    let flashLabel = 'FLASH SALE';
    const exclusiveLabel = 'EXCLUSIVE DEAL';
    const lifetimeLabel = 'LIFETIME PRICE';
    let flashColor = '#EC0014';
    if (holiday.promoLabel.length > 2) {
        flashLabel = String(holiday.promoLabel).toUpperCase();
        flashColor = holiday.color;
    }
    return (
        <>
            <div
                className={
                    site !== undefined && site.siteItemProps.hasFlash === true && site.priceItemProps.price
                        ? `${styles.flashSaleCard} ${styles.cardContainer}`
                        : `${styles.cardContainer}`
                }
                style={{ borderColor: flashColor }}>
                <div className={styles.cardContent}>
                    {/* TOP CARD && LABEL && SCORE */}
                    <div className={styles.cardThumb} ref={observedThumb}>
                        <a
                            href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${
                                site.priceItemProps.price && site.siteItemProps.isActive
                                    ? `/OpenSite.aspx?SiteID=${site.siteItemProps.id}${dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? '&linkType=deals-page' : ''}`
                                    : `/porn/reviews/${linkValidator(site.siteItemProps.slug)}`
                            }`}
                            rel={relCategory}
                            target="_blank"
                            title={'Visit ' + site.siteItemProps.name}
                            data-opensite-action={
                                site.priceItemProps.isFlash === true ? `${pageTag}flashthumb` : `${pageTag}thumb`
                            }
                            data-opensite-category="Opensite"
                            data-opensite-path={
                                site.priceItemProps.isFlash === true
                                    ? `outgoing,${pageTag},flash-thumb`
                                    : `outgoing,${pageTag},thumb`
                            }
                            data-opensite-site={site.siteItemProps.id}
                            onClick={() => createAlgoliaEvent('conversion', 'Card Thumbnail')}>
                            <InView
                                triggerOnce
                                onChange={(InView) => {
                                    ThumbLoger(InView, thumbInfo.thumbID, thumb, siteLink);
                                }}>
                                <Image
                                    unoptimized
                                    alt={`Site thumbnail`}
                                    src={imgSrc}
                                    width={195}
                                    height={105}
                                    layout={'responsive'}
                                    data-thumbid={thumbInfo.thumbID}
                                    priority={index < 3 ? true : false}
                                    onError={() => {
                                        setImgSrc(placeHolderCard);
                                    }}
                                    placeholder="blur"
                                    blurDataURL={placeHolderCard}
                                />
                            </InView>
                            {/* H3 TITLE */}
                            <h3 className={`${styles.cardTitle}`}>{site.siteItemProps.name}</h3>
                        </a>
                        {/* LABELS TOP CARD */}
                        {site.siteItemProps.isTopSite === true && site.priceItemProps.price ? (
                            <div className={styles.topWebsiteLabel}>RABBITS TOP SITE</div>
                        ) : null}

                        {site.priceItemProps.price && (site.siteItemProps.hasFlash || site.priceItemProps.lifetimeDiscount) ? (
                            <div className={styles.flashLabel}>
                                {site.priceItemProps.lifetimeDiscount ? (
                                    <div className={styles.lifeTimeDiscountLabel}>
                                        <CheckMark />
                                        <p className="flashLabel">{lifetimeLabel}</p>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {site.priceItemProps.isFlash ? (
                                    <div className={styles.flashSaleLabel}>
                                        <p className="flashLabel">{flashLabel}</p>
                                        <p className="exclusiveLabel hidden">{exclusiveLabel}</p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                        {/* Score Box - Bottom Right */}
                        <div className={styles.categoryScoreBox}>
                            <div className={styles.score}>
                                SCORE <br /> <span>{site.siteItemProps.totalScore}%</span>
                            </div>
                            {(site.categoryItemProps.name && isDisplay) || (site.categoryItemProps.slug && isDisplay) ? (
                                <Link
                                    href={
                                        process.env.NEXT_PUBLIC_APP_DOMAIN +
                                        '/porn/categories/' +
                                        linkValidator(site.categoryItemProps.slug).toLowerCase()
                                    }>
                                    <a
                                        className={`${styles.catLink}`}
                                        title={'link to ' + site.categoryItemProps.name + ' sites category'}>
                                        {site.categoryItemProps.name.toUpperCase()}
                                    </a>
                                </Link>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                {/* Price, description */}
                <div className={`${styles.cardMiddle}`}>
                    {site.siteItemProps.isActive && site.priceItemProps.price ? (
                        <div
                            className={`${styles.cardPrices}  prd-it-prc prd-it-prc-promo mob-card AB-1644 bottom-hk text-roboto`}>
                            {String(site.priceItemProps.price).toLowerCase() !== 'n/a' ? (
                                <div className={`${styles.rowPricesSale} col col-1 mob-card AB-1644`}>
                                    <div className={styles.firstRow}>
                                        <div
                                            className={`${
                                                String(site.priceItemProps.price).split('.')[0].length > 2
                                                    ? ''
                                                    : styles.singleDigitSalePrice
                                            } ${styles.salePrice} prd-it-prc-sale`}>
                                            {' '}
                                            {String(site.priceItemProps.price).split('.')[0]}
                                            <sup>{String(site.priceItemProps.price).split('.')[1]}</sup>
                                        </div>
                                        <div className={`${styles.regularPrice} prd-it-prc-rg prd-it-prc-onsale`}>
                                            {' '}
                                            {site.priceItemProps.regularPrice}{' '}
                                        </div>
                                    </div>
                                    <div className={styles.secondRow}>
                                        <div
                                            className={`${
                                                String(site.priceItemProps.price).split('.')[0].length > 2
                                                    ? ''
                                                    : styles.singleDigitPrice
                                            } ${styles.priceFrequency} prd-it-prc-fqc mob-card AB-1644`}>
                                            {site.priceItemProps.length + ' ' + site.priceItemProps.unit}{' '}
                                        </div>

                                        <p className={styles.prctOff}>
                                            SAVE{' '}
                                            {calculateSavings(site.priceItemProps.price, site.priceItemProps.regularPrice) + '%'}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className={`${styles.rowPricesRegular} col col-1 mob-card AB-1644`}>
                                    <div
                                        className={`${styles.regularPrice} ${styles.regularPriceNoDiscount} prd-it-prc-rg prd-it-prc-onsale`}>
                                        {' '}
                                        {String(site.priceItemProps.regularPrice).split('.')[0]}
                                        <sup>{String(site.priceItemProps.regularPrice).split('.')[1]}</sup>
                                    </div>
                                    <div
                                        className={`${styles.priceFrequency} ${styles.priceFrequencyNoDiscount} prd-it-prc-fqc mob-card AB-1644`}>
                                        {site.priceItemProps.length + ' ' + site.priceItemProps.unit}{' '}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    {/* customHTML payload if exist. Default display description */}
                    {customHTML && customHTML.description === false ? (
                        ''
                    ) : (
                        <p className={`${styles.description} ${site.priceItemProps.price ? '' : styles.longerDesc}`}>
                            {site.siteItemProps.catListDescription}
                        </p>
                    )}
                </div>
                {/* CTAs */}
                <article className={`${styles.cardBottom}`}>
                    <Link
                        href={
                            process.env.NEXT_PUBLIC_APP_DOMAIN +
                            `/porn/${
                                dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? 'deals' : 'reviews'
                            }/${linkValidator(site.siteItemProps.slug).toLowerCase()}`
                        }>
                        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                        <a
                            data-event-category="User Action"
                            data-event-action={`${pageTag}-sitename-review`}
                            data-event-label={site.siteItemProps.name}
                            title={'Read ' + site.siteItemProps.name + "'s" + ' full review'}
                            className={`${styles.fullReviewLink} ${styles.fullReviewSiteTitle} ${site.siteItemProps.isActive && site.priceItemProps.price ? styles.fullReviewSiteTitleSmall : styles.fullReviewSiteTitleDefault}`}
                            onClick={() => createAlgoliaEvent('click', 'Card Full Review')}
                            onKeyDown={(e) => e.key === 'Enter' && createAlgoliaEvent('click', 'Card Full Review')}>
                            {site.siteItemProps.name}
                            <br />
                            <span className={styles.noBold}>
                                {dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1
                                    ? 'Deals & Discounts '
                                    : 'FULL REVIEW'}
                            </span>
                        </a>
                    </Link>
                    {site.siteItemProps.isActive && site.priceItemProps.price ? (
                        <VisitSiteCTA
                            path={
                                process.env.NEXT_PUBLIC_APP_DOMAIN +
                                `/OpenSite.aspx?SiteID=${site.siteItemProps.id}${dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? '&linkType=deals-page' : ''}`
                            }
                            hasFlash={site.siteItemProps.hasFlash}
                            relCategory={relCategory}
                            savings={site.priceItemProps.savings}
                            openSitePath={
                                site.siteItemProps.hasFlash === true ? `outgoing,${pageTag},flash-cta` : `outgoing,${pageTag},cta`
                            }
                            openSiteCategory="Opensite"
                            openSiteSite={site.siteItemProps.id}
                            eventAction={site.siteItemProps.hasFlash === true ? `${pageTag}flash-cta` : `${pageTag}cta`}
                            createAlgoliaEvent={createAlgoliaEvent}
                        />
                    ) : (
                        ''
                    )}
                </article>
            </div>
        </>
    );
}
